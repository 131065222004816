import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TinySlider from 'tiny-slider-react';
import Lightbox from 'react-18-image-lightbox';

import Navbar from '@/component/Navbar/navbar';
import Footer from '@/component/Footer/footer';

import image from '@/assets/images/portfolio/1.jpg';
import image2 from '@/assets/images/portfolio/3.jpg';
import image3 from '@/assets/images/portfolio/4.jpg';
import image4 from '@/assets/images/portfolio/5.jpg';
import image5 from '@/assets/images/portfolio/6.jpg';
import image6 from '@/assets/images/portfolio/7.jpg';
import image7 from '@/assets/images/portfolio/8.jpg';
import image8 from '@/assets/images/portfolio/9.jpg';
import image9 from '@/assets/images/portfolio/20.jpg';
import image10 from '@/assets/images/portfolio/21.jpg';
import image11 from '@/assets/images/portfolio/22.jpg';
import image12 from '@/assets/images/portfolio/23.jpg';

import { ROUTES } from '@/constants/routes';

const settings2 = {
  container: '.tiny-six-item',
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: 'bottom',
  controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
  nav: false,
  speed: 400,
  gutter: 0,
  responsive: {
    1025: {
      items: 6,
    },

    992: {
      items: 4,
    },

    767: {
      items: 3,
    },

    320: {
      items: 1,
    },
  },
};
export default function PageServices() {
  const [isOpen, setisOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const services = [
    {
      image: image,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image2,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image3,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image4,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image5,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image6,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image7,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image8,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image9,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image10,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image11,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image12,
      name: 'Iphone mockup',
      role: 'Branding',
    },
  ];
  const images = [
    image,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
  ];

  const handleMovePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + images.length - 1) % images.length);
  };

  const handleMoveNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setisOpen(true);
  };
  const currentImage = images[currentImageIndex];

  return (
    <>
      <Navbar navClass="nav-light" />

      <section className="relative table w-full py-36 lg:py-44 bg-[url('../../assets/images/services.jpg')] bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0 bg-black opacity-75"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h5 className="text-white/50 text-lg font-medium">¿Que ofrecemos?</h5>
            <h3 className="mt-2 md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">
              Nuestros Servicios
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className=" tracking-[0.5px]  mb-0 inline-block space-x-1">
            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to={ROUTES.HOME}>BR MAPIA</Link>
            </li>
            <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <i className="uil uil-angle-right-b"></i>
            </li>
            <li
              className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Servicios
            </li>
          </ul>
        </div>
      </section>

      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
            <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 transition duration-500 rounded-2xl mt-6 text-center">
              <div className="w-20 h-20 bg-teal-600/5 text-teal-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i className="uil uil-constructor"></i>
              </div>

              <div className="content mt-7">
                <div className="title h5 text-lg font-medium hover:text-teal-600">
                  Servicios de automatización industrial
                </div>
                <p className="text-slate-400 mt-3 text-start">
                  <li>Diseño y armado de tableros eléctricos.</li>
                  <li>
                    Programación de variadores ABB/SIEMENS/YASKAWA y programación de PLCs
                    Siemens/ALLEN BRADLEY.
                  </li>
                  <li>Electrohidráulica/Electroneumática.</li>
                  <li>Mecanica general / Mecanica de Prensas.</li>
                  <li>Automatización de procesos y retrofits.</li>
                </p>
              </div>
            </div>

            <div className="p-6 shadow-xl shadow-gray-100 dark:shadow-gray-800 transition duration-500 rounded-2xl mt-6 text-center">
              <div className="w-20 h-20 bg-teal-600/5 text-teal-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i className="uil uil-fire"></i>
              </div>

              <div className="content mt-7">
                <div className="title h5 text-lg font-medium hover:text-teal-600">
                  Mantenimiento a facilities, subestaciones y sistemas contra incendio.
                </div>
                <p className="text-slate-400 mt-3 text-start">
                  <li>
                    Mantenimiento de instalaciones eléctrica (Portones automáticos, iluminación y
                    centros de carga).
                  </li>
                  <li>Mantenimiento de instalaciones hidráulicas y sanitarias.</li>
                  <li>Impermeabilización</li>
                  <li>Jardinería y mantenimiento a sistema de riego.</li>
                  <li>Mantenimiento a edificio (albañilería, impermeabilización, pintura)</li>
                </p>
              </div>
            </div>

            <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 transition duration-500 rounded-2xl mt-6 text-center">
              <div className="w-20 h-20 bg-teal-600/5 text-teal-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i className="uil uil-house-user"></i>
              </div>

              <div className="content mt-7">
                <div className="title h5 text-lg font-medium hover:text-teal-600">
                  Mantenimiento a casa habitación
                </div>
                <p className="text-slate-400 mt-3 text-start">
                  <li>
                    Plomería, electricidad, climatización, jardinería, seguridad, albañilería y
                    mantenimiento general.
                  </li>
                  <li>Suministro e instalación de sistemas de celdas solares.</li>
                  <li>Suministro e instalación de sistemas de hidroneumáticos.</li>
                  <li>Suministro e instalación de calentadores solares.</li>
                  <li>Suministro e instalación de filtros para purificación de agua.</li>
                  <li>Suministro e instalación de sistemas de aire acondicionado.</li>
                  <li>Suministro e instalación de controles automáticos para portones.</li>
                </p>
              </div>
            </div>

            <div className="p-6 shadow-xl shadow-gray-100 dark:shadow-gray-800 transition duration-500 rounded-2xl mt-6 text-center">
              <div className="w-20 h-20 bg-teal-600/5 text-teal-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i className="uil uil-drill"></i>
              </div>

              <div className="content mt-7">
                <div className="title h5 text-lg font-medium hover:text-teal-600">
                  Pailería, herrería y soldadura en general.
                </div>
                <p className="text-slate-400 mt-3 text-start">
                  <li>Fabricación de proyecciones de herreria.</li>
                  <li>Fabricación de estructuras.</li>
                  <li>Diseño y fabricación de pergolas de herrería.</li>
                  <li>Mantenimiento a portones.</li>
                  <li>Fabricación y reparación de racks.</li>
                </p>
              </div>
            </div>

            <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 transition duration-500 rounded-2xl mt-6 text-center">
              <div className="w-20 h-20 bg-teal-600/5 text-teal-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i className="uil uil-data-sharing"></i>
              </div>

              <div className="content mt-7">
                <div className="title h5 text-lg font-medium hover:text-teal-600">
                  Ingeniería, diseño y domótica
                </div>
                <p className="text-slate-400 mt-3 text-start">
                  <li>Diseño y modelado en CAD.</li>
                  <li>Ingeniería eléctrica y mecánica.</li>
                  <li>Diseño de circuitos neumáticos e hidráulicos.</li>
                  <li>
                    Domótica (Apagadores inteligentes, control de IR y RF, camaras inteligentes,
                    riegos automáticos, focos inteligentes y mas..).
                  </li>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 items-end">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Nuestros últimos proyectos
            </h3>
            <p className="text-slate-400 max-w-xl">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </div>

        <div className="container-fluid relative mt-8">
          <div className="grid grid-cols-1 mt-8">
            <div className="tiny-six-item">
              <TinySlider settings={settings2}>
                {services.map((item, index) => {
                  return (
                    <div className="tiny-slide" key={index}>
                      <div className="group relative block overflow-hidden rounded-md transition-all duration-500 mx-2">
                        <Link
                          to="#"
                          onClick={() => handleImageClick(index)}
                          className="lightbox transition-all duration-500 group-hover:scale-105"
                          title=""
                        >
                          <img src={item.image} className="" alt="" />
                        </Link>
                        <div className="absolute -bottom-52 group-hover:bottom-2 start-2 end-2 transition-all duration-500 bg-white dark:bg-slate-900 p-4 rounded shadow dark:shadow-gray-800">
                          <Link
                            to="/portfolio-detail-two"
                            className="hover:text-teal-600 text-lg transition duration-500 font-medium"
                          >
                            {item.name}
                          </Link>
                          <h6 className="text-slate-400">{item.role}</h6>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </TinySlider>
              {isOpen && (
                <Lightbox
                  mainSrc={currentImage}
                  prevSrc={images[(currentImageIndex + images.length - 1) % images.length]}
                  nextSrc={images[(currentImageIndex + 1) % images.length]}
                  onCloseRequest={() => setisOpen(false)}
                  onMovePrevRequest={handleMovePrev}
                  onMoveNextRequest={handleMoveNext}
                />
              )}
            </div>
          </div>
        </div>
        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 text-center">
            <h6 className="text-teal-600 text-sm font-bold mb-2">BR MAPIA es tu mejor opción</h6>
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              ¿Tienes un proyecto en mente? <br /> Hablemos.
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              ¡Mantén tu mundo funcionando sin problemas - un servicio a la vez!
            </p>

            <div className="mt-6">
              <Link
                to={ROUTES.CONTACT_US}
                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-teal-600 hover:bg-teal-700 border-teal-600 hover:border-teal-700 text-white rounded-md mt-4"
              >
                <i className="uil uil-phone"></i> Contactanos
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
