import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import IndexService from '@/pages/index/indexService';
import PageServices from '@/pages/company/pageServices';
import PageAboutUs from '@/pages/company/pageAboutUs';
import ContactDetail from '@/pages/contact/contactDetail';
import Switch from '@/component/Switch';

import 'tiny-slider/dist/tiny-slider.css';
import 'react-18-image-lightbox/style.css';
import 'react-modal-video/css/modal-video.css';
import '@/assets/libs/@iconscout/unicons/css/line.css';
import '@/assets/libs/@mdi/font/css/materialdesignicons.min.css';
import '@/assets/css/tailwind.css';

import { ROUTES } from '@/constants/routes';

export default function App() {
  return (
    <BrowserRouter>
      <Switch />
      <Routes>
        <Route path={ROUTES.HOME} element={<IndexService />} />
        <Route path={ROUTES.SERVICES} element={<PageServices />} />
        <Route path={ROUTES.ABOUT_US} element={<PageAboutUs />} />
        <Route path={ROUTES.CONTACT_US} element={<ContactDetail />} />
      </Routes>
    </BrowserRouter>
  );
}
