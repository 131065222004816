import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Search } from 'feather-icons-react/build/IconComponents';
import TinySlider from 'tiny-slider-react';
import CountUp from 'react-countup';

import Navbar from '@/component/Navbar/navbar';
import Footer from '@/component/Footer/footer';
import CookieModal from '@/component/cookieModal';

import thyssenkrupp from '@/assets/images/mapia/thyssenkrupp.svg';
import quickpipes from '@/assets/images/mapia/quickpipes.svg';
import arcelormittal from '@/assets/images/mapia/arcelormittal.svg';
import cjf from '@/assets/images/mapia/cjf.svg';
import nkpm from '@/assets/images/mapia/nkpm.svg';
import schnellecke from '@/assets/images/mapia/schnellecke.svg';

import svg from '@/assets/images/illustrator/maintenance.svg';

import { SERVICES } from '@/pages/index/data/services';
import { CUSTOMER_EXPERIENCES } from '@/pages/index/data/customersExperiences';
import { FREQUENT_QUESTIONS } from '@/pages/index/data/frequentQuestions';
import { ROUTES } from '@/constants/routes';

const settings = {
  container: '.tiny-single-item',
  items: 1,
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: 'bottom',
  speed: 400,
  gutter: 16,
};

export default function IndexService() {
  const [activeIndex, setActiveIndex] = useState(1);

  const accordionData = FREQUENT_QUESTIONS;

  const toggleAccordion = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };

  const accordionData1 = accordionData.filter((x) => x.id < 5);

  return (
    <>
      <Navbar />

      <section className="relative table w-full pt-36 lg:pt-44 pb-32 bg-[url('../../assets/images/home-shape.png')] bg-top bg-no-repeat bg-cover">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="md:col-span-7">
              <div className="md:me-6">
                <h4 className="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">
                  El mejor servicio de <br /> <span className="text-teal-600">mantenimiento</span>{' '}
                  <br />
                  <TypeAnimation
                    sequence={[
                      ' en tu hogar',
                      2000,
                      ' en tu empresa',
                      2000,
                      ' en tu edificio',
                      2000,
                      ' en tu oficina',
                      2000,
                      ' en donde sea!',
                      2000,
                    ]}
                    wrapper="span"
                    speed={50}
                    className="typewrite"
                    repeat={Infinity}
                  />
                </h4>
                <p className="text-slate-400 text-xl max-w-xl">
                  ¡Mantén tu mundo funcionando sin problemas - un servicio a la vez!
                </p>
              </div>
            </div>

            <div className="md:col-span-5">
              <img src={svg} alt="" />
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-6 grid-cols-2 justify-center gap-[30px]">
            <div className="mx-auto py-4">
              <img src={thyssenkrupp} className="h-20" alt="" />
            </div>
            <div className="mx-auto py-4">
              <img src={cjf} className="h-20" alt="" />
            </div>
            <div className="mx-auto py-4">
              <img src={arcelormittal} className="h-20" alt="" />
            </div>
            <div className="mx-auto py-4">
              <img src={nkpm} className="h-20" alt="" />
            </div>
            <div className="mx-auto py-4">
              <img src={quickpipes} className="h-20" alt="" />
            </div>
            <div className="mx-auto py-4">
              <img src={schnellecke} className="h-20" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="relative">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-black dark:text-white">
              Nuestros servicios
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Somos una empresa dedicada a proporcionar servicios de mantenimiento de alta calidad
              para hogares y empresas en toda la región.
            </p>
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {SERVICES.map((item, index) => {
              return (
                <div
                  key={index}
                  className="group transition-all duration-500 ease-in-out text-center"
                >
                  <div className="flex align-middle mx-auto justify-center items-center w-14 h-14 mt-1 bg-teal-600/5 group-hover:bg-teal-600 group-hover:text-white border-2 border-teal-600/20 text-teal-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                    <i className={item.icon}></i>
                  </div>
                  <div className="mt-4">
                    <Link
                      to="#"
                      className="text-xl font-medium hover:text-indigo-600 duration-500 ease-in-out"
                    >
                      {item.title}
                    </Link>
                    <p className="text-slate-400 mt-2">{item.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="grid grid-cols-1 text-center">
            <div className="mt-20">
              <Link
                to={ROUTES.SERVICES}
                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-teal-600 hover:bg-teal-700 border-teal-600 hover:border-teal-700 text-white rounded-md"
              >
                Ver más
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:pb-24 pb-16 bg-[url('../../assets/images/home-shape.png')] bg-opacity-10 bg-center bg-no-repeat bg-cover">
        <div className="container relative md:mt-24 lg:pt-24 pt-16">
          <div className="absolute inset-0 opacity-25 dark:opacity-50 bg-[url('@/assets/images/map.png')] bg-no-repeat bg-center bg-cover"></div>
          <div className="relative grid grid-cols-1 pb-8 text-center z-1">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-black dark:text-white">
              Mas de 10k de clientes satisfechos
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Nuestro objetivo es mantener sus hogares y empresas en excelentes condiciones para que
              puedan funcionar de manera efectiva y segura.
            </p>
          </div>

          <div className="relative grid md:grid-cols-3 grid-cols-1 items-center mt-8 gap-[30px] z-1">
            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-slate-400 dark:text-white">
                <CountUp className="counter-value" end={1548} start={1010} />+
              </h1>
              <h5 className="counter-head text-lg font-medium">Servicios</h5>
            </div>

            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-slate-400 dark:text-white">
                <CountUp className="counter-value" end={25} start={2} />+
              </h1>
              <h5 className="counter-head text-lg font-medium">Automatizaciones</h5>
            </div>

            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-slate-400 dark:text-white">
                <CountUp className="counter-value" end={9} start={0} />+
              </h1>
              <h5 className="counter-head text-lg font-medium">Años de experienza</h5>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:pb-24 pb-16">
        <div className="container relative lg:pt-24 pt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-black dark:text-white">
              Lo que dicen nuestros clientes
            </h3>
          </div>

          <div className="flex justify-center relative mt-16">
            <div className="relative lg:w-1/3 md:w-1/2 w-full">
              <div className="absolute -top-20 md:-start-24 -start-0">
                <i className="mdi mdi-format-quote-open text-9xl opacity-5"></i>
              </div>

              <div className="absolute bottom-28 md:-end-24 -end-0">
                <i className="mdi mdi-format-quote-close text-9xl opacity-5"></i>
              </div>
              <TinySlider settings={settings}>
                {CUSTOMER_EXPERIENCES.map((item, index) => (
                  <div className="tiny-slide" key={index}>
                    <div className="text-center">
                      <p className="text-lg text-slate-400 italic"> {item.feedback} </p>

                      <div className="text-center mt-5">
                        <ul className="text-xl font-medium text-amber-400 list-none mb-2">
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                        </ul>

                        <img
                          src={item.image}
                          className="h-14 w-14 rounded-full shadow-md dark:shadow-gray-800 mx-auto"
                          alt=""
                        />
                        <h6 className="mt-2 font-semibold">{item.name}</h6>
                        <span className="text-slate-400 text-sm">{item.role}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </TinySlider>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Preguntas frecuentes
            </h3>
          </div>

          <div
            id="accordion-collapse"
            data-accordion="collapse"
            className="grid md:grid-cols-1 grid-cols-1 mt-8 md:gap-[30px]"
          >
            <div>
              {accordionData1.map((item, index) => (
                <div
                  key={index}
                  className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4"
                >
                  <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                    <button
                      type="button"
                      onClick={() => toggleAccordion(item.id)}
                      className={`flex justify-between items-center p-5 w-full font-medium text-start ${
                        activeIndex === item.id ? 'bg-gray-50 dark:bg-slate-800 text-teal-600' : ''
                      }`}
                      data-accordion-target="#accordion-collapse-body-1"
                      aria-expanded="true"
                      aria-controls="accordion-collapse-body-1"
                    >
                      <span>{item.title}</span>
                      <svg
                        data-accordion-icon
                        className={`${
                          activeIndex === item.id ? 'rotate-180' : 'rotate-270'
                        } w-4 h-4 shrink-01`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </h2>
                  {activeIndex === item.id && (
                    <div>
                      <div className="p-5">
                        <p className="text-slate-400 dark:text-gray-400">{item.content}</p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              ¿Deseas saber mas?
            </h3>
            <div className="mt-6">
              <Link
                to={ROUTES.CONTACT_US}
                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-teal-600 hover:bg-teal-700 border-teal-600 hover:border-teal-700 text-white rounded-md"
              >
                <i className="uil uil-phone align-middle me-2"></i> Contactanos
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <CookieModal />
    </>
  );
}
