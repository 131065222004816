import image from '@/assets/images/client/01.jpg';
import image1 from '@/assets/images/client/02.jpg';
import image2 from '@/assets/images/client/03.jpg';
import image3 from '@/assets/images/client/04.jpg';
import image4 from '@/assets/images/client/05.jpg';
import image5 from '@/assets/images/client/06.jpg';

export const CUSTOMER_EXPERIENCES = [
  {
    feedback:
      '" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. " ',
    name: 'Christa Smith',
    role: 'Manager',
    image: image,
  },
  {
    feedback:
      '" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "',
    name: 'Christa Smith',
    role: 'Manager',
    image: image1,
  },
  {
    feedback:
      '" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "',
    name: 'Christa Smith',
    role: 'Manager',
    image: image2,
  },
  {
    feedback:
      '" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "',
    name: 'Christa Smith',
    role: 'Manager',
    image: image3,
  },
  {
    feedback:
      '" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "',
    name: 'Christa Smith',
    role: 'Manager',
    image: image4,
  },
  {
    feedback:
      '" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "',
    name: 'Christa Smith',
    role: 'Manager',
    image: image5,
  },
];
