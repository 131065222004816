import React from 'react';
import { Mail } from 'feather-icons-react';
import { Link } from 'react-router-dom';

import logo_light from '@/assets/images/mapia/logo-horizontal-dark.png';
import american_ex from '@/assets/images/payments/american-ex.png';
import discover from '@/assets/images/payments/discover.png';
import master_card from '@/assets/images/payments/master-card.png';
import paypal from '@/assets/images/payments/paypal.png';
import visa from '@/assets/images/payments/visa.png';
import { ROUTES } from '@/constants/routes';

export default function Footer() {
  const footerLinks = [
    {
      liClass: '',
      route: '/page-terms',
      title: 'Términos y condiciones',
    },
    {
      liClass: 'mt-[10px]',
      route: '/page-privacy',
      title: 'Política de privacidad',
    },
  ];
  const footerCompany = [
    {
      liClass: '',
      route: ROUTES.ABOUT_US,
      title: 'Acerca de nosotros',
    },
    {
      liClass: 'mt-[10px]',
      route: ROUTES.SERVICES,
      title: 'Servicios',
    },
  ];
  return (
    <div>
      <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
        <div className="container relative">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <div className="py-[60px] px-0">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                  <div className="lg:col-span-4 md:col-span-12">
                    <Link to="/#" className="text-[22px] focus:outline-none">
                      <img style={{ width: 150 }} src={logo_light} alt="" />
                    </Link>
                    <p className="mt-6 text-gray-300">
                      ¡Mantén tu mundo funcionando sin problemas - un servicio a la vez!
                    </p>
                    <ul className="list-none mt-5 space-x-1 space-y-1">
                      <li className="inline">
                        <Link
                          to="#"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <i className="uil uil-shopping-cart align-middle" title="Buy Now"></i>
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="#"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <i className="uil uil-dribbble align-middle" title="dribbble"></i>
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="#"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <i className="uil uil-behance" title="Behance"></i>
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="#"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <i className="uil uil-linkedin" title="Linkedin"></i>
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="#"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <i className="uil uil-facebook-f align-middle" title="facebook"></i>
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="#"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <i className="uil uil-instagram align-middle" title="instagram"></i>
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="#"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <i className="uil uil-twitter align-middle" title="twitter"></i>
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="mailto:support@mapia.in"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <i className="uil uil-envelope align-middle" title="email"></i>
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="https://forms.gle/QkTueCikDGqJnbky9"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <i className="uil uil-file align-middle" title="customization"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="lg:col-span-2 md:col-span-4">
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">BR MAPIA</h5>
                    <ul className="list-none footer-list mt-6">
                      {footerCompany.map((data, index) => (
                        <li key={index} className={data.liClass}>
                          <Link
                            to={data.route}
                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i className="uil uil-angle-right-b"></i> {data.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="lg:col-span-3 md:col-span-4">
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">Otros</h5>
                    <ul className="list-none footer-list mt-6">
                      {footerLinks.map((data, index) => (
                        <li key={index} className={data.liClass}>
                          <Link
                            to={data.route}
                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i className="uil uil-angle-right-b"></i> {data.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="lg:col-span-3 md:col-span-4">
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">Newsletter</h5>
                    <p className="mt-6">
                      Subscribete a nuestro newsletter y recibe lo ultimo en tips de mantenimiento.
                    </p>
                    <form>
                      <div className="grid grid-cols-1">
                        <div className="foot-subscribe my-3">
                          <label className="form-label">
                            Escribe tu email <span className="text-red-600">*</span>
                          </label>
                          <div className="form-icon relative mt-2">
                            <Mail className="w-4 h-4 absolute top-3 start-4" />
                            <input
                              type="email"
                              className="form-input ps-12 rounded w-full py-2 px-3 h-10 bg-gray-800 border-0 text-gray-100 focus:shadow-none focus:ring-0"
                              placeholder="Email"
                              name="email"
                              required={false}
                            />
                          </div>
                        </div>

                        <button
                          type="submit"
                          id="submitsubscribe"
                          name="send"
                          className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-teal-600 hover:bg-teal-700 border-teal-600 hover:border-teal-700 text-white rounded-md"
                        >
                          Suscribirme
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-[30px] px-0 border-t border-slate-800">
          <div className="container relative text-center">
            <div className="grid md:grid-cols-2 items-center">
              <div className="md:text-start text-center">
                <p className="mb-0">
                  © {new Date().getFullYear()} BR MAPIA. Design & Develop by{' '}
                  <Link to="" target="_blank" className="text-reset">
                    cloudgia.com
                  </Link>
                </p>
              </div>

              <ul className="list-none md:text-end text-center space-x-1 mt-6 md:mt-0">
                <li className="inline">
                  <Link to="#">
                    <img
                      src={american_ex}
                      className="max-h-6 inline"
                      title="American Express"
                      alt=""
                    />
                  </Link>
                </li>
                <li className="inline">
                  <Link to="#">
                    <img src={discover} className="max-h-6 inline" title="Discover" alt="" />
                  </Link>
                </li>
                <li className="inline">
                  <Link to="#">
                    <img src={master_card} className="max-h-6 inline" title="Master Card" alt="" />
                  </Link>
                </li>
                <li className="inline">
                  <Link to="#">
                    <img src={paypal} className="max-h-6 inline" title="Paypal" alt="" />
                  </Link>
                </li>
                <li className="inline">
                  <Link to="#">
                    <img src={visa} className="max-h-6 inline" title="Visa" alt="" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
