export const FREQUENT_QUESTIONS = [
  {
    id: 1,
    title: 'How does it work ?',
    content:
      'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
  },
  {
    id: 2,
    title: 'How does it work ?',
    content:
      'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
  },
  {
    id: 3,
    title: 'How does it work ?',
    content:
      'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
  },
  {
    id: 4,
    title: 'How does it work ?',
    content:
      'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
  },
  {
    id: 5,
    title: 'How does it work ?',
    content:
      'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
  },
  {
    id: 6,
    title: 'Do I need a designer to use Techwind ?',
    content:
      'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
  },
  {
    id: 7,
    title: 'What do I need to do to start selling ?',
    content:
      'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
  },
  {
    id: 8,
    title: 'What happens when I receive an order ?',
    content:
      'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
  },
];
