export const SERVICES = [
  {
    icon: 'uil uil-constructor',
    title: 'Industrial',
    desc: 'Servicios de automatización industrial',
  },
  {
    icon: 'uil uil-fire',
    title: 'Facilities',
    desc: 'Mantenimiento a facilities, subestaciones y sistemas contra incendio',
  },
  {
    icon: 'uil uil-house-user',
    title: 'Casa habitación',
    desc: 'Mantenimiento a casa habitación (plomería, electricidad, climatización, jardinería, seguridad, albañilería y mantenimiento general).',
  },
  {
    icon: 'uil uil-drill',
    title: 'Pailería',
    desc: 'Pailería, herrería y soldadura en general.',
  },
  {
    icon: 'uil uil-data-sharing',
    title: 'Ingeniería',
    desc: 'Ingeniería, diseño y domótica',
  },
];
