import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TinySlider from 'tiny-slider-react';
import ModalVideo from 'react-modal-video';
import CountUp from 'react-countup';
import {
  Eye,
  Facebook,
  Feather,
  Heart,
  Instagram,
  Layout,
  Linkedin,
  Monitor,
  Twitter,
} from 'feather-icons-react/build/IconComponents';

import Navbar from '@/component/Navbar/navbar';
import Footer from '@/component/Footer/footer';

import ab03 from '@/assets/images/about/ab03.jpg';
import ab02 from '@/assets/images/about/ab02.jpg';
import ab01 from '@/assets/images/about/ab01.jpg';

import thyssenkrupp from '@/assets/images/mapia/thyssenkrupp.svg';
import quickpipes from '@/assets/images/mapia/quickpipes.svg';
import arcelormittal from '@/assets/images/mapia/arcelormittal.svg';
import cjf from '@/assets/images/mapia/cjf.svg';
import nkpm from '@/assets/images/mapia/nkpm.svg';
import schnellecke from '@/assets/images/mapia/schnellecke.svg';

import cta_bg from '@/assets/images/cta-bg.jpg';
import hero1 from '@/assets/images/hero1.png';
import jorge from '@/assets/images/mapia/jorge.png';
import defaultUser from '@/assets/images/mapia/default-user.jpg';

import { ROUTES } from '@/constants/routes';

const settings = {
  container: '.tiny-single-item',
  items: 1,
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: 'bottom',
  speed: 400,
  gutter: 16,
};

export default function PageAboutUs() {
  const [isOpen, setOpen] = useState(false);

  const services = [
    {
      description:
        '" If the distribution of letters and \'words\' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "',
      name: 'Thomas Israel',
      image: thyssenkrupp,
    },
    {
      description:
        '" If the distribution of letters and \'words\' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "',
      name: 'Carl Oliver',
      image: cjf,
    },
    {
      description:
        '" If the distribution of letters and \'words\' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "',
      name: 'Barbara McIntosh',
      image: arcelormittal,
    },
    {
      description:
        '" If the distribution of letters and \'words\' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "',
      name: 'Jill Webb',
      image: nkpm,
    },
    {
      description:
        '" If the distribution of letters and \'words\' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "',
      name: 'Dean Tolle',
      image: quickpipes,
    },
    {
      description:
        '" If the distribution of letters and \'words\' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "',
      name: 'Christa Smith',
      image: schnellecke,
    },
  ];
  const features = [
    { icon: Monitor, title: 'Puntualidad' },
    { icon: Heart, title: 'Calidad' },
    { icon: Eye, title: 'Honestidad' },
    { icon: Layout, title: 'Precios justos' },
    { icon: Feather, title: 'Trato humano' },
  ];
  const teamData = [
    {
      image: jorge,
      name: 'Jorge Bernal Rodríguez',
      title: 'Gerente General',
      desc: "If the distribution of letters and 'words' is random",
      linkedin: 'https://www.linkedin.com/in/jorge-bernal-rodr%C3%ADguez-16a748a9/',
    },
    {
      image: defaultUser,
      name: 'Joel Torres Montiel',
      title: 'Jefe de Servicio',
      desc: "If the distribution of letters and 'words' is random",
      linkedin: '',
    },
    {
      image: defaultUser,
      name: 'Guillermo Rodríguez Mtz',
      title: 'Jefe Administrativo',
      desc: "If the distribution of letters and 'words' is random",
      linkedin: '',
    },
  ];

  return (
    <>
      <Navbar navClass="nav-light" />
      <section className="relative table w-full py-36 lg:py-44 bg-[url('../../assets/images/company/aboutus.jpg')] bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0 bg-black opacity-75"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="mb-6 md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">
              Acerca de nosotros
            </h3>

            <p className="text-slate-300 text-lg max-w-xl mx-auto">
              En BR MAPIA nos dedicamos a proporcionar servicios de mantenimiento de alta calidad
              para hogares y empresas en toda la región.
            </p>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className=" tracking-[0.5px]  mb-0 inline-block space-x-1">
            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to={ROUTES.HOME}>BR MAPIA</Link>
            </li>
            <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <i className="uil uil-angle-right-b"></i>
            </li>
            <li
              className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Acerca de nosotros
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-5 md:col-span-6">
              <div className="grid grid-cols-12 gap-6 items-center">
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img src={ab03} className="shadow rounded-md" alt="" />
                    <img src={ab02} className="shadow rounded-md" alt="" />
                  </div>
                </div>

                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img src={ab01} className="shadow rounded-md" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:col-span-7 md:col-span-6">
              <div className="lg:ms-5">
                <div className="flex mb-4">
                  <span className="text-teal-600 text-2xl font-bold mb-0">
                    <CountUp
                      className="counter-value text-6xl font-bold"
                      start={1}
                      end={15}
                    ></CountUp>
                    +
                  </span>
                  <span className="self-end font-medium ms-2">
                    Años <br /> de experiencia
                  </span>
                </div>

                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                  ¿Quienes somos?
                </h3>

                <p className="text-slate-400 max-w-xl">
                  BR MAPIA nace de la idea de...Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                  ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur.
                </p>

                <div className="mt-6">
                  <Link
                    to={ROUTES.CONTACT_US}
                    className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-teal-600 hover:bg-teal-700 border-teal-600 hover:border-teal-700 text-white rounded-md me-2 mt-2"
                  >
                    <i className="uil uil-envelope"></i> Contactanos
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container relative mt-8">
          <div className="grid md:grid-cols-6 grid-cols-2 justify-center gap-[30px]">
            <div className="mx-auto mt-8">
              <img src={thyssenkrupp} className="h-20" alt="" />
            </div>
            <div className="mx-auto mt-8">
              <img src={cjf} className="h-20" alt="" />
            </div>
            <div className="mx-auto mt-8">
              <img src={arcelormittal} className="h-20" alt="" />
            </div>
            <div className="mx-auto mt-8">
              <img src={nkpm} className="h-20" alt="" />
            </div>
            <div className="mx-auto mt-8">
              <img src={quickpipes} className="h-20" alt="" />
            </div>
            <div className="mx-auto mt-8">
              <img src={schnellecke} className="h-20" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              Nuestros valores
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              En BR MAPIA lo mas importante son nuestros clientes, es por eso que cada día nos
              esforzarnos por ofrecer el mejor servicio.
            </p>
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] mt-8">
            {features.map((item, index) => {
              const Icon = item.icon;
              return (
                <div
                  key={index}
                  className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900"
                >
                  <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-teal-600/10 text-teal-600 text-center rounded-full me-3">
                    <Icon className="h-5 w-5 rotate-45"></Icon>
                  </div>
                  <div className="flex-1">
                    <h4 className="mb-0 text-lg font-medium">{item.title}</h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800 md:pt-0 pt-0">
        <div className="container relative">
          <div className="grid grid-cols-1 justify-center">
            <div className="relative z-1">
              <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                <div className="lg:col-start-2 lg:col-span-10">
                  <div className="relative">
                    <img src={cta_bg} className="rounded-md shadow-lg" alt="" />
                    <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                      <Link
                        to="#"
                        onClick={() => setOpen(true)}
                        data-type="youtube"
                        data-id="S_CGed6E610"
                        className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-teal-600 dark:text-white"
                      >
                        <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="JDKy-pdWMDs"
                onClose={() => setOpen(false)}
              />
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 start-0 end-0 sm:h-2/3 h-4/5 bg-gradient-to-b from-teal-500 to-teal-600"></div>
      </section>

      <section
        className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800 md:pb-0 pb-0"
        id="team"
      >
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              Nuestro equipo
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Con mas de 15 años de experiencia en el mercado nos comprometemos a brindarte el mejor
              servicio.
            </p>
          </div>

          <div className="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
            {teamData.map((item, index) => {
              return (
                <div key={index} className="lg:col-span-4 md:col-span-4">
                  <div className="team p-6 rounded-md border border-gray-100 dark:border-gray-700 group bg-white dark:bg-slate-900">
                    <img
                      src={item.image}
                      className="h-24 w-24 rounded-full shadow-md dark:shadow-gray-800"
                      alt=""
                    />

                    <div className="content mt-4">
                      <Link to="#" className="text-lg font-medium hover:text-teal-600 block">
                        {item.name}
                      </Link>
                      <span className="text-slate-400 block">{item.title}</span>

                      <p className="text-slate-400 mt-4">{item.desc}</p>

                      <ul className="list-none mt-4 space-x-1">
                        <li className="inline">
                          <Link
                            to="#"
                            className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center border border-gray-100 dark:border-gray-800 rounded-md hover:border-teal-600 dark:hover:border-teal-600 hover:bg-teal-600 hover:text-white"
                          >
                            <Facebook className="h-4 w-4"></Facebook>
                          </Link>
                        </li>
                        <li className="inline">
                          <Link
                            to="#"
                            className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center border border-gray-100 dark:border-gray-800 rounded-md hover:border-teal-600 dark:hover:border-teal-600 hover:bg-teal-600 hover:text-white"
                          >
                            <Instagram className="h-4 w-4"></Instagram>
                          </Link>
                        </li>
                        <li className="inline">
                          <Link
                            to="#"
                            className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center border border-gray-100 dark:border-gray-800 rounded-md hover:border-teal-600 dark:hover:border-teal-600 hover:bg-teal-600 hover:text-white"
                          >
                            <Twitter className="h-4 w-4"></Twitter>
                          </Link>
                        </li>
                        <li className="inline">
                          <Link
                            target="_blank"
                            to={item.linkedin}
                            className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center border border-gray-100 dark:border-gray-800 rounded-md hover:border-teal-600 dark:hover:border-teal-600 hover:bg-teal-600 hover:text-white"
                          >
                            <Linkedin className="h-4 w-4"></Linkedin>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center">
            <div className="lg:col-span-5 md:col-span-6 md:order-1 order-2">
              <div className="relative overflow-hidden after:content-[''] after:absolute after:h-40 after:w-40 after:bg-red-600/5 after:top-5 after:start-0 after:end-0 after:mx-auto after:-z-0 after:rounded-3xl after:animate-[spin_10s_linear_infinite]">
                <img src={hero1} className="relative z-1" alt="" />
              </div>
            </div>

            <div className="lg:col-span-7 md:col-span-6 md:order-2 order-1">
              <div className="tiny-single-item">
                <TinySlider settings={settings}>
                  {services.map((item, index) => (
                    <div className="tiny-slide text-center" key={index}>
                      <img src={item.image} className="h-20 mx-auto" alt="" />
                      <p className="text-slate-400 mt-6">{item.description}</p>
                      <h6 className="text-teal-600 font-semibold mt-3">- {item.name}</h6>
                    </div>
                  ))}
                </TinySlider>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
